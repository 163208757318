import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  VStack,
} from "@chakra-ui/react";
import { useSetSmsResetTokenMutation } from "../users/usersApiSlice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoginModalOpen } from "./loginModalOpenSlice";
import { setSmsCode } from "./smsCodeSlice";
import { useSendSmsCodeMutation } from "../sms/smsApiSlice";

const SmsResetComponent = () => {
  const [loading, setLoading] = useState(false);
  const smsCode = useSelector((state) => state.smsCode.smsCode);
  const [codeValid, setCodeValid] = useState(false);
  const [send, { data: token, isSuccess }] = useSetSmsResetTokenMutation();
  const [sendCode, { data: numberVerification }] = useSendSmsCodeMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const PHONE_REGEX = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{3})/;
  const [validPhone, setValidPhone] = useState(false);
  const [smsLoading, setSmsLoading] = useState(false);
  const [usersPhone, setUsersPhone] = useState("");

  const onPhoneChange = (event) => {
    setUsersPhone(event.target.value);
    setValidPhone(PHONE_REGEX.test(event.target.value));
  };
  const sendSms = async () => {
    //numberVerification = null;
    setSmsLoading(true);
    const newSmsCode = dispatch(
      setSmsCode(Math.floor(100000 + Math.random() * 500000))
    );
    await sendCode({ nr: usersPhone, code: newSmsCode });
  };
  const setToken = () => {
    setLoading(true);
    send({ usersPhone: usersPhone });
  };
  useEffect(() => {
    if (token) {
      if (token.status !== 404) {
        dispatch(setLoginModalOpen(false));
        // // // console.log(token);
        setLoading(false);
        navigate(`/reset/${token.token}`);
      }
    }
  }, [token]);
  useEffect(() => {
    // console.log(`numberVerification`);
    // console.log(numberVerification);
    // console.log(`numberVerification`);
    setSmsLoading(false);
  }, [numberVerification]);
  const checkCode = (e) => {
    // // // console.log(e.target.value);
    // // // console.log(smsCode);
    if (e.target.value === smsCode.toString()) setCodeValid(true);
  };
  // useEffect(() => {
  //   // // // console.log(`smsCode`);
  //   // // // console.log(smsCode);
  //   // // // console.log(`smsCode`);
  // }, [smsCode]);

  // {!smsLoading ? ( ) : (
  //   <Box w="100%" align={"center"}>
  //     <Spinner size={"sm"} color={"blue.200"} />
  //   </Box>
  // )}

  return (
    <>
      {!smsLoading ? (
        <VStack w={"100%"}>
          {numberVerification?.status === 200 ? (
            <Box
              bg={"#fafafa"}
              border={"1px solid #eaeaea"}
              p={10}
              borderRadius={5}
            >
              <FormControl>
                <Input
                  textAlign={"center"}
                  placeholder="kod sms"
                  w={"150px"}
                  type="text"
                  bg={"white"}
                  id="code"
                  onChange={checkCode}
                  autoComplete="off"
                  align={"center"}
                  required
                />
              </FormControl>
              <Button
                marginTop={3}
                isLoading={loading}
                onClick={() => {
                  setToken();
                }}
                isDisabled={!codeValid}
                colorScheme="blue"
                bg={"blue.300"}
              >
                RESETUJ HASŁO
              </Button>
            </Box>
          ) : (
            <>
              <FormControl id="phoneform" isRequired>
                <FormLabel>nr telefonu</FormLabel>
                <Input
                  className="form__input"
                  type="phone"
                  id="phone"
                  onChange={onPhoneChange}
                  autoComplete="off"
                  required
                />
              </FormControl>
              {numberVerification?.status === 404 && (
                <Box w={"100%"}>
                  <p className={"small alert"}>Podano błędny numer tel.</p>
                </Box>
              )}
              <Button
                align={"center"}
                isLoading={loading}
                isDisabled={!validPhone}
                w={"100%"}
                p={"5px 15px 6px 15px"}
                m={3}
                colorScheme="blue"
                bg={"blue.300"}
                onClick={() => {
                  sendSms();
                }}
              >
                WYŚLIJ SMSA
              </Button>
            </>
          )}
        </VStack>
      ) : (
        <Box w="100%" align={"center"}>
          <Spinner size={"sm"} color={"blue.200"} />
        </Box>
      )}
    </>
  );
};
export default SmsResetComponent;
