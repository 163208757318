import { useHandleServiceMutation } from "./servicesApiSlice";
import { useDispatch } from "react-redux";
import { setSave } from "./saveServiceSlice";
import { Button, Spinner } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { EditIcon, CloseIcon, CheckIcon } from "@chakra-ui/icons";
import { setMinute } from "../services/selectedMinuteSlice";
import { setItemsId } from "../items/selectedItemsSlice";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { setIsServiceModalOpen } from "./isServiceModalOpenSlice";
import { setModalButtonDisabled } from "./modalButtonDisabledSlice";
import { setStToDel } from "../st/stToDelSlice";
import { setStId } from "../st/selectedStSlice";
import { setServiceModalMode } from "./serviceModalModeSlice";
import { setEditedServiceId } from "./editedServiceIdSlice";
import { setUserObj } from "../users/selectedUserSlice";
import { setVehicle } from "../vehicles/selectedVehicleSlice";
import { setHour } from "./selectedHourSlice";
import { setDate } from "../calendar/selectedDateSlice";
import { useEffect } from "react";

const SetServiceDone = ({ appointment }) => {
  const selectedStId = useSelector((state) => state.selectedSt.stIds);
  const dispatch = useDispatch();
  const [doneService, { data: done, isLoading, isSuccess, isError, error }] =
    useHandleServiceMutation();
  const doneS = async () => {
    // if (appointment.items) {
    //   dispatch(setItemsId(appointment.items));
    // }
    doneService({
      id: appointment._id,
      user: appointment.user,
      vehicle: appointment.vehicle,
      garage: appointment.garage,
      date: appointment.date,
      hour: appointment.hour,
      st: appointment.sts,
      minute: appointment.minute,
      items: appointment.items,
      notes: appointment.uwagi,
      title: appointment.title,
      text: appointment.text,
      completed: "done",
    });
  };
  if (isSuccess) {
  }

  const setUpModal = ({ date, mode }) => {
    dispatch(setModalButtonDisabled(false));
    dispatch(setStToDel([]));
    dispatch(setStId(appointment.sts));
    dispatch(setServiceModalMode(mode));
    dispatch(setEditedServiceId(appointment._id));
    if (appointment.items) {
      dispatch(setItemsId(appointment.items));
      // console.log(`selectedItems`);
      // console.log(appointment.sts);
      // console.log(appointment.items);
    }
    dispatch(setUserObj(appointment.user));
    dispatch(setVehicle(appointment.vehicle));
    dispatch(setDate(date));
    dispatch(setHour(appointment.hour));
    dispatch(setMinute(appointment.minute));
    dispatch(setIsServiceModalOpen(true));
    // // dispatch(setIsServiceModalOpen(true));
    // dispatch(
    //   setDate(format(new Date(`${year}/${month}/${day}`), "dd/MM/yyyy")),
    // );
    // setTime(hour);
  };
  useEffect(() => {
    if (error) {
      console.log(`error.data2`);
      // setUpModal({ date: appointment.date, mode: "edit" });
      console.log(error);
      if (error) setUpModal({ date: appointment.date, mode: "edit" });
    }
  }, [error]);
  // if (isError) {
  //   console.log(`error.data`);
  //   // setUpModal({ date: appointment.date, mode: "edit" });
  //   console.log(error);
  // }
  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && (
        <Button
          isDisabled={appointment?.user === null}
          colorScheme="whatsapp"
          margin="0 10px 0 0"
          size="sm"
          bg={"transparent"}
          onClick={() => {
            doneS();
          }}
        >
          <FontAwesomeIcon
            w="5px"
            size="lg"
            height="5px"
            color="#a1c957"
            icon={faCheck}
          />
        </Button>
      )}
    </>
  );
};
export default SetServiceDone;
