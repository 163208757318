import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import { HStack, Button, Container, Box } from "@chakra-ui/react";
import {
  EditIcon,
  CloseIcon,
  faCheck,
  faCalendarCheck,
} from "@chakra-ui/icons";
import ClientRowPart from "./ClientRowPart";
import ItemRowPart from "./ItemRowPart";
import { useGetServicesQuery } from "./servicesApiSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { setIsServiceModalOpen } from "./isServiceModalOpenSlice";
import { setDate } from "../calendar/selectedDateSlice";
import { setServiceModalMode } from "./serviceModalModeSlice";
import DelServiceComponent from "./DelServiceComponent";
import ApproveService from "./ApproveService";
import { setModalButtonDisabled } from "./modalButtonDisabledSlice";
import { setVehicle } from "../vehicles/selectedVehicleSlice";
import { setUserObj } from "../users/selectedUserSlice";
import { setHour } from "./selectedHourSlice";
import { setMinute } from "./selectedMinuteSlice";
import {
  setEditedServiceId,
  setEditedServiceNotes,
} from "./editedServiceIdSlice";
import { setItemsId } from "../items/selectedItemsSlice";
import { setStId } from "../st/selectedStSlice";
import ServiceDetails from "./ServiceDetails";
import { setStToDel } from "../st/stToDelSlice";
import SetServiceEditable from "./SetServiceEditable";
import { useSelector } from "react-redux";
import SetServiceDone from "./SetServiceDone";
import PdfComponent from "../pdf/PdfComponent";
import ItemDetails from "../items/ItemDetails";
import { setNrs } from "../sms/selectedNrsSlice";
import ServiceItemsTable from "./ServiceItemsTable";
import { setIsServiceAnonymous } from "./isServiceAnonymousSlice";

const ServiceRow = ({
  length,
  index,
  toApprove,
  appointment,
  myClass,
  serviceStatusColor,
}) => {
  // const selectedItems = useSelector((state) => state.selectedItems.itemsIds);
  //// // console.log(appointment);
  const dispatch = useDispatch();

  const setUpModal = ({ date, mode }) => {
    if (appointment.uwagi) dispatch(setEditedServiceNotes(appointment.uwagi));
    else dispatch(setEditedServiceNotes(""));
    if (appointment.user === null) {
      dispatch(setIsServiceAnonymous(true));
    } else {
      dispatch(setIsServiceAnonymous(false));
    }
    // // console.log(`setUpModal`);
    // // console.log(appointment);
    // // console.log(`setUpModal`);
    dispatch(setModalButtonDisabled(false));
    dispatch(setStToDel([]));
    dispatch(setStId(appointment.sts));
    dispatch(setServiceModalMode(mode));
    dispatch(setEditedServiceId(appointment._id));
    if (appointment.items) {
      dispatch(setItemsId(appointment.items));
      // //// // console.log(`selectedItems`);
      // //// // console.log(appointment.sts);
      // //// // console.log(appointment.items);
    }
    dispatch(setUserObj(appointment.user));
    dispatch(setVehicle(appointment.vehicle));
    dispatch(setDate(date));
    dispatch(setHour(appointment.hour));
    dispatch(setMinute(appointment.minute));
    dispatch(setIsServiceModalOpen(true));
    // dispatch(setIsServiceModalOpen(true));
    // dispatch(
    //   setDate(format(new Date(`${year}/${month}/${day}`), "dd/MM/yyyy")),
    // );
    // setTime(hour);
  };
  const smsHandler = () => {
    dispatch(setNrs([appointment.user]));
    dispatch(setServiceModalMode("sms"));
    dispatch(setIsServiceModalOpen(true));
  };
  if (appointment) {
    let items = appointment.items?.map((item) => (
      <ItemDetails
        edit="false"
        view="serviceRow"
        key={appointment._id}
        item={item}
      />
    ));
    // //// // console.log(appointment.sts);
    return (
      <>
        <Td
          // bg={serviceStatusColor}
          className={`minute ${
            index !== 0 && (index === length - 1 || !index)
              ? " index0"
              : " index1"
          }`}
          // className={myClass}
        >
          <span className={`gray small bold`}>
            {" "}
            <span className="gray"> {appointment.hour} : </span>
            {appointment.minute}
            {appointment.minute === 0 && "0"}
          </span>
        </Td>
        <Td
          className={` ${
            index !== 0 && (index === length - 1 || !index)
              ? " index0"
              : " index1"
          }`}
        >
          <HStack>
            {toApprove === "suggested" ? (
              <ApproveService appointment={appointment} />
            ) : null}
            {toApprove === "done" ? (
              <SetServiceEditable appointment={appointment} />
            ) : null}
            {toApprove === "approved" ? (
              <SetServiceDone appointment={appointment} />
            ) : null}

            {appointment.user?._id && (
              <Button
                onClick={() => {
                  smsHandler();
                }}
                color={"blue.300"}
                size={"sm"}
                bg="transparent"
              >
                <FontAwesomeIcon size={"lg"} icon={faComment} />
              </Button>
            )}
            <PdfComponent appointment={appointment} />
          </HStack>
        </Td>
        <Td
          className={` ${
            index !== 0 && (index === length - 1 || !index)
              ? " index0"
              : " index1"
          }`}
        >
          <Box
            padding={5}
            borderRadius="10px"
            bg={serviceStatusColor}
            className={myClass}
          >
            <HStack spacing="24px">
              {/* {appointment?.user?._id && ( */}
              <div>
                {toApprove === "done" ? (
                  <Button
                    isDisabled="true"
                    colorScheme="yellow"
                    size="sm"
                    onClick={() =>
                      setUpModal({ date: appointment.date, mode: "edit" })
                    }
                  >
                    <EditIcon w={4} h={4} color="#fff" />
                  </Button>
                ) : (
                  <Button
                    colorScheme="yellow"
                    size="sm"
                    onClick={() =>
                      setUpModal({ date: appointment.date, mode: "edit" })
                    }
                  >
                    <EditIcon w={4} h={4} color="#fff" />
                  </Button>
                )}
              </div>
              {/* / )} */}
              {appointment?.user?._id && (
                <div>
                  <ClientRowPart
                    user={appointment.user}
                    vehicle={appointment.vehicle}
                  />
                </div>
              )}
            </HStack>
          </Box>
        </Td>

        <Td
          className={` ${
            index !== 0 && (index === length - 1 || !index)
              ? " index0"
              : " index1"
          }`}
          // bg={serviceStatusColor}
          // className={myClass}
        >
          {/* {appointment._id} */}
          {appointment.items && appointment?.user?._id && (
            <ServiceItemsTable items={items} />
          )}
        </Td>
        <Td
          className={` ${
            index !== 0 && (index === length - 1 || !index)
              ? " index0"
              : " index1"
          }`}
          // bg={serviceStatusColor} className={myClass}
        >
          {appointment.sts &&
            appointment.sts.map((st, index) => (
              <ServiceDetails key={index} index={index} st={st} />
            ))}
        </Td>

        <Td
          className={`${
            index !== 0 && (index === length - 1 || !index)
              ? " index0"
              : " index1"
          }`}
          borderRadius=" 0 10px 10px 0"
          // bg={serviceStatusColor}
          // className={myClass}
        >
          <div className={"textwrap"} width={"100%"}>
            <p className={"small"}>{appointment.uwagi}</p>
          </div>
        </Td>
        <Td
          className={` ${
            index !== 0 && (index === length - 1 || !index)
              ? " index0"
              : " index1"
          }`}
        >
          <DelServiceComponent appointment={appointment} />
        </Td>
      </>
    );
  } else {
    return (
      <>
        <Td className={`${myClass} index0`}></Td>
        <Td className={`${myClass} index0`}></Td>
        <Td className={`${myClass} index0`}></Td>
        <Td className={`${myClass} index0`}></Td>
        <Td className={`${myClass} index0`}></Td>
        <Td className={`${myClass} index0`}></Td>
        <Td className={`${myClass} index0`}></Td>
      </>
    );
  }
};

export default ServiceRow;
