import { createSlice } from "@reduxjs/toolkit";

export const smsCodeSlice = createSlice({
  name: "smsCode",
  initialState: {
    smsCode: null,
  },
  reducers: {
    setSmsCode: (state, action) => {
      // // // console.log(action.payload);
      state.smsCode = action.payload;
    },
  },
});

export const { setSmsCode } = smsCodeSlice.actions;
export default smsCodeSlice.reducer;
