import { Box, HStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const DayOfWeekComponent = ({ paramsYear, paramsMonth, paramsDay }) => {
  let selectedDate = useSelector((state) => state.selectedDate.date);
  const [date, setDate] = useState([]);
  const [dayOfWeek, setDayOfWeek] = useState(0);
  const days = ["Po", "Wt", "Śr", "Cz", "Pi", "So", "Ni"];
  const [content, setContent] = useState(<></>);

  useEffect(() => {
    const newDate = Date.parse(`${paramsYear}-${paramsMonth}-${paramsDay}`);
    // console.log(`newDate`);
    // console.log(paramsYear);
    // console.log(new Date(parseInt(newDate)));
    // console.log(new Date(parseInt(newDate)).getDay());
    // console.log(`newDate`);
    setDayOfWeek(new Date(parseInt(newDate)).getDay());
  }, [paramsDay]);

  useEffect(() => {
    setContent(
      days.map((d, index) => (
        <Box
          className={`small gray ${index + 1 === dayOfWeek ? "bold" : ""}`}
          h={"25px"}
          p={"3px 5px 7px 5px"}
          color={index + 1 === dayOfWeek ? "blue.300" : "#afafaf"}
          bg={index + 1 === dayOfWeek ? "blue.50" : ""}
          borderRadius={5}
        >
          {d}
        </Box>
      ))
    );
  }, [dayOfWeek]);

  return <HStack>{content}</HStack>;
};

export default DayOfWeekComponent;
