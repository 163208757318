import { createSlice } from "@reduxjs/toolkit";

export const isServiceAnonymousSlice = createSlice({
  name: "isServiceAnonymous",
  initialState: {
    isServiceAnonymous: false,
  },
  reducers: {
    setIsServiceAnonymous: (state, action) => {
      // // console.log(action.payload);
      state.isServiceAnonymous = action.payload;
    },
  },
});

export const { setIsServiceAnonymous } = isServiceAnonymousSlice.actions;
export default isServiceAnonymousSlice.reducer;
