import { useRef, useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCredentials } from "./authSlice";
import { useLoginMutation } from "./authApiSlice";
import usePersist from "../../hooks/usePersist";
import { setLoginModalOpen } from "./loginModalOpenSlice";
import useTitle from "../../hooks/useTitle";
import PulseLoader from "react-spinners/PulseLoader";
import {
  FormControl,
  Input,
  useDisclosure,
  FormLabel,
  useToast,
  Box,
  Button,
  Container,
  Spinner,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Textarea,
  Tabs,
} from "@chakra-ui/react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import NewUserForm from "../users/NewUserForm";
import GoogleLoginComponent from "./GoogleLoginComponent";
import ResetPasswordComponent from "./ResetPasswordComponent";
import { setSmsCode } from "./smsCodeSlice";

const LoginModal = ({ children, url }) => {
  const userAuth = useSelector((state) => state.auth.token);
  const loginModalOpen = useSelector(
    (state) => state.loginModalOpen.loginModalOpen
  );
  useTitle("Employee Login");
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });

  const userRef = useRef();
  const errRef = useRef();
  const [uemail, setUemail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [persist, setPersist] = usePersist();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [login, { data: loginResponse, isLoading, isSuccess, isError, error }] =
    useLoginMutation();
  const [remember, setRemember] = useState(false);
  useEffect(() => {
    if (userAuth) {
      // // console.log(userAuth);
      // onClose();
    }
  }, [userAuth]);
  useEffect(() => {
    dispatch(setSmsCode(null));
    setRemember(false);
    if (loginModalOpen == true) {
      onOpen();
    } else {
      // console.log(`loginmodalOpen: ${loginModalOpen}`);
      onClose();
    }
  }, [loginModalOpen]);

  useEffect(() => {
    setErrMsg("");
  }, [uemail, password]);
  if (isSuccess) {
    // // // console.log(loginResponse);
    // setLoginModalOpen(false);
  }

  const handleSubmit = async (e) => {
    setErrMsg(`"Missing email or Password": ${uemail} ${password}`);
    e.preventDefault();
    try {
      const { accessToken } = await login({
        email: uemail,
        password,
      }).unwrap();
      setErrMsg(`"2Missing Username or Password": ${accessToken} ${password}`);
      dispatch(setCredentials({ accessToken }));
      setUemail("");
      setPassword("");
      // navigate("/dash");
      // navigate(0);
    } catch (err) {
      setErrMsg(`"3err": ${err} ${err.message}`);
      if (!err.status) {
        setErrMsg("No Server Response");
      } else if (err.status === 400) {
        setErrMsg("Missing email or Password");
      } else if (err.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg(err.data?.message);
      }
    }
  };

  const handleUserInput = (e) => setUemail(e.target.value);
  const handlePwdInput = (e) => setPassword(e.target.value);
  const handleToggle = () => setPersist((prev) => !prev);

  const errClass = errMsg ? "errmsg" : "offscreen";

  if (isError) {
    // // console.log(error);
  }
  // if (isLoading)
  //   return (
  //     <Box
  //       border={"1px solid #9fd2ed"}
  //       padding={"11px 12px 5px 15px"}
  //       margin={"0px 0px 0px 15px"}
  //       borderRadius={"5px"}
  //     >
  //       <Spinner
  //         thickness="3px"
  //         speed="0.65s"
  //         emptyColor="gray.200"
  //         color="blue.100"
  //         size="sm"
  //       />
  //     </Box>
  //   );
  const closeModal = () => {
    setRemember(false);
    dispatch(setLoginModalOpen(false));
    onClose();
  };
  const content = (
    <>
      <Modal isOpen={loginModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton onClick={closeModal} />
          <ModalBody>
            <section className="public">
              <main className="login">
                <Tabs variant="soft-rounded">
                  <TabList mb="1em">
                    <Tab borderRadius={6} width="50%">
                      Zaloguj
                    </Tab>
                    <Tab borderRadius={6} width="50%">
                      Utwórz konto
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      {remember === false ? (
                        <>
                          <FormControl id="uemail" isRequired>
                            <FormLabel>Email</FormLabel>
                            <Input
                              className="form__input"
                              type="text"
                              id="uemail"
                              ref={userRef}
                              value={uemail}
                              onChange={handleUserInput}
                              autoComplete="off"
                              required
                            />
                          </FormControl>

                          <FormControl id="password" isRequired>
                            <FormLabel>Hasło</FormLabel>
                            <Input
                              className="form__input"
                              type="password"
                              id="password"
                              onChange={handlePwdInput}
                              value={password}
                              required
                            />
                          </FormControl>
                          <br />
                          <Button
                            isLoading={isLoading}
                            width="100%"
                            bg={"blue.300"}
                            marginBottom={"15px"}
                            colorScheme="blue"
                            onClick={handleSubmit}
                          >
                            Loguj
                          </Button>
                        </>
                      ) : (
                        <ResetPasswordComponent mode="modal" />
                      )}
                      <Button
                        bg={"transparent"}
                        borderWidth={1}
                        borderColor={"blue.300"}
                        borderRadius={5}
                        color={"blue.400"}
                        w={"100%"}
                        onClick={() => {
                          setRemember(!remember);
                        }}
                        marginBottom="20px"
                        width="100%"
                        align="center"
                      >
                        {remember === false
                          ? "nie pamiętam hasła..."
                          : "anuluj"}
                      </Button>
                      <div className="googlelogincomponent">
                        <GoogleLoginComponent />
                      </div>
                      <div></div>
                    </TabPanel>
                    <TabPanel>
                      <NewUserForm isAdmin={false} />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </main>
            </section>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );

  return content;
};
export default LoginModal;
