import { Button, Spinner, useDisclosure } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useDelItemMutation } from "./itemsApiSlice";
import { useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { setRefreshItemsByUser } from "../../hooks/refreshSlice";
import { editItemsId, setItemsId } from "./selectedItemsSlice";
import { setUserObj } from "../users/selectedUserSlice";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from "@chakra-ui/react";

const DelItemComponent = ({ iid }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const dispatch = useDispatch();
  const [confirm, setConfirm] = useState(
    <>
      <Button
        onClick={() => {
          delConfirm();
        }}
        size="sm"
        colorScheme="red"
        color="white"
      >
        <FontAwesomeIcon icon={faXmark} />
      </Button>
    </>
  );
  useEffect(() => {
    setConfirm(
      <>
        <Button
          onClick={() => {
            delConfirm();
          }}
          size="sm"
          colorScheme="red"
          color="white"
        >
          <FontAwesomeIcon icon={faXmark} />
        </Button>
      </>
    );
  }, [iid]);
  const [delItem, { data: resp, isLoading, isSuccess, isError, error }] =
    useDelItemMutation();
  let content = confirm;
  const delConfirm = () => {
    onOpen();
    // setConfirm(
    //   <>
    //     <Button
    //       onClick={() => {
    //         deleteItem();
    //       }}
    //       size="sm"
    //       colorScheme="red"
    //       color="white"
    //     >
    //       Czy usunąć przedmiot {iid} ?
    //     </Button>
    //   </>
    // );
  };

  const deleteItem = () => {
    // dispatch(setRefreshItemsByUser(false));
    delItem({ iid: iid });
  };
  useEffect(() => {
    // console.log(resp);
  }, [resp]);
  if (isLoading) {
    content = (
      <Button size="sm" colorScheme="red">
        <Spinner />
      </Button>
    );
  }
  if (isSuccess) {
    // dispatch(setRefreshItemsByUser(true));
    // content = (
    //   <>
    //     <Button
    //       isLoading={isLoading}
    //       onClick={() => {
    //         delConfirm();
    //       }}
    //       size="sm"
    //       colorScheme="red"
    //       color="white"
    //     >
    //       <FontAwesomeIcon icon={faXmark} />
    //     </Button>
    //   </>
    // );
  }
  if (isError) {
    // console.log(error);
    content = <p>{}</p>;
  }

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Customer
            </AlertDialogHeader>

            <AlertDialogBody>
              Czy usunąć przedmiot {iid} ? Tej czynności nie można cofnąć...
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Anuluj
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  deleteItem();
                  onClose();
                }}
                ml={3}
              >
                Usuń pozycję
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {content}
    </>
  );
};

export default DelItemComponent;
