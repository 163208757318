import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Progress,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import ItemsTable from "./ItemsTable";
import { setItemModalMode } from "./itemModalModeSlice";
import { useParams } from "react-router-dom";
import { Spinner } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useGetItemsQuery } from "../items/itemsApiSlice";
import ItemsModal from "./ItemsModal";
import useAuth from "../../hooks/useAuth";

const ItemsView = () => {
  const dispatch = useDispatch();
  let content = <Progress size="xs" isIndeterminate />;
  const UserInfo = useAuth();
  const { garageId, iid } = useParams();
  const {
    data: items,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetItemsQuery(
    { gid: garageId, uid: UserInfo._id, detailsId: iid },
    "itemsList",
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );
  useEffect(() => {
    dispatch(setItemModalMode(null));
  }, []);
  // if (isLoading) console.log(items);
  if (isError) console.log(error);
  if (isSuccess) {
    const { ids, entities } = items;
    const itemsList = ids?.map((iid) => entities[iid]);
    content = (
      <>
        {/* <ItemsModal mode={"top"} key="itemsModal" /> */}
        <ItemsModal owner={"hidden"} key="itemsModal" />
        <ItemsTable itemsRaw={itemsList} />
      </>
    );
  }
  return content;
};
export default ItemsView;
