import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Spinner,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  TabPanel,
  Tabs,
  TabList,
  Tab,
  TabPanels,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SendEmail from "../mailer/SendEmail";
import useAuth from "../../hooks/useAuth";
import SmsResetComponent from "./SmsResetComponent";

const ResetPasswordComponent = ({ mode }) => {
  // // // // console.log(`ResetPasswordComponent: ${mode}`);
  const UserInfo = useAuth();
  let content = <></>;
  const dispatch = useDispatch();
  const [status, setStatus] = useState("pending");
  const [message, setMessage] = useState("");
  const smsCode = useSelector((state) => state.smsCode.smsCode);
  const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const [usersEmail, setUsersEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);

  const onEmailChange = (event) => {
    setUsersEmail(event.target.value);
    setValidEmail(EMAIL_REGEX.test(event.target.value));
  };
  const form = (
    <>
      <Tabs align="center">
        <TabList>
          <Tab>reset emailem</Tab>
          <Tab>reset telefonem</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <FormControl id="emal" isRequired>
              <FormLabel>Twój email</FormLabel>
              <Input
                className="form__input"
                type="email"
                id="email"
                onChange={onEmailChange}
                autoComplete="off"
                required
              />
            </FormControl>
            {status === "pending" ? (
              <VStack w={"100%"}>
                <p>{message}</p>
                <Button
                  isDisabled={!validEmail}
                  w={"100%"}
                  p={"5px 15px 6px 15px"}
                  m={3}
                  colorScheme="blue"
                  bg={"blue.300"}
                  onClick={() => {
                    send();
                  }}
                  className="buttonBigTxt"
                >
                  ZRESETUJ HASŁO
                </Button>
              </VStack>
            ) : (
              message
            )}
          </TabPanel>
          <TabPanel>
            {status === "pending" ? <SmsResetComponent /> : message}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );

  useEffect(() => {
    // setMessage(`Czy zresetować hasło?`);
  }, []);
  const garage = useSelector((state) => state.selectedGarage.garage);
  const sendSms = () => {};
  const send = async () => {
    let body = {};
    if (mode !== "modal") {
      body = {
        task: "resetPassword",
        garage: garage,
        email: UserInfo.email,
        user: UserInfo,
        notes: "",
        author: UserInfo._id,
        authorname: UserInfo.username,
      };
    } else {
      body = {
        task: "resetPassword",
        garage: null,
        email: usersEmail,
        user: {},
        notes: "",
        author: "",
        authorname: usersEmail,
      };
    }
    // // // // console.log(`body`);
    // // // // console.log(body);
    setMessage(<Spinner />);
    setMessage(<SendEmail task="resetPassword" body={body} />);
    setStatus("sent");
  };
  if (validEmail) {
    content = <></>;
  }
  return (
    <>
      {mode === "modal" && form} {content}
    </>
  );
};
export default ResetPasswordComponent;
