import {
  Box,
  Button,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faX } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const ServiceItemsTable = ({ items }) => {
  const [showItems, setShowItems] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleShow = () => {
    setShowItems(!showItems);
  };
  return (
    <>
      {showItems === true ? (
        <VStack
          align={"left"}
          alignContent={"left"}
          alignItems={"left"}
          textAlign={"left"}
          alignSelf={"left"}
        >
          <Button w={"15px"} onClick={handleShow} color="red" size={"sm"}>
            <FontAwesomeIcon icon={faXmark} />
          </Button>
          <Box
            border="1px solid #eee"
            background={"#fff"}
            borderRadius="5px"
            overflowY="auto"
            className="left w100 fixedTable"
            maxWidth="580px"
          >
            <TableContainer overflowY="scroll" className="left w100 fixedTable">
              <Table variant="simple">
                <Thead bg={"#fff"}>
                  <Tr>
                    <Th>opis (nazwa)</Th>
                    <Th>parametry</Th>
                    <Th>marka/model</Th>
                    <Th>typ</Th>
                    <Th>magazyn</Th>
                    <Th>ilość</Th>
                    <Th>uwagi</Th>
                    <Th>zmodyfikowano</Th>
                  </Tr>
                </Thead>
                <Tbody>{items}</Tbody>
              </Table>
            </TableContainer>
          </Box>
        </VStack>
      ) : (
        <Button
          onClick={handleShow}
          className="primaryBackground"
          color="gray.300"
          size={"sm"}
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </Button>
      )}
    </>
  );
};

export default ServiceItemsTable;
