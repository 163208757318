import {
  Button,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Input,
  FormControl,
  useDisclosure,
  useToast,
  Container,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Textarea,
  Tabs,
  InputLeftAddon,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { setIsItemsModalOpen } from "./isItemsModalOpenSlice";
import TBody from "./TBody";
import { setItemModalMode } from "./itemModalModeSlice";
import { Spinner } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useGetItemsByUsernameMutation } from "./itemsApiSlice";
import { setSearchedItem } from "./searchedItemSlice";

const ItemsTable = ({ itemsRaw, view }) => {
  const selectedItems = useSelector(
    (state) => state.selectedItems.selectedItems
  );
  const { garageId } = useParams();
  const [items, setItems] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [
    getItemsByUsername,
    { data: itemsByUsername, isLoading, isSuccess, isError, error },
  ] = useGetItemsByUsernameMutation();
  useEffect(() => {
    if (itemsByUsername) {
      const { ids, entities } = itemsByUsername;
      const itemsBU = ids.map((iid) => entities[iid]);
      if (itemsBU) {
        setFilteredData(itemsBU);
        setItems(itemsBU);
      }
    }
  }, [itemsByUsername]);
  const dispatch = useDispatch();
  const isItemsModalOpen = useSelector(
    (state) => state.isItemsModalOpen.isItemsModalOpen
  );
  const searchedItem = useSelector((state) => state.searchedItem.searchedItem);
  const idChanged = (e) => {
    // console.log(`[]idChanged`);
    // console.log(itemsByUsername);
    // console.log(e);
    // console.log(`[]idChanged`);
    let searchWord = "";
    if (e?.target) searchWord = e?.target.value;
    else if (e?.value) searchWord = e?.value;
    if (searchWord) dispatch(setSearchedItem(searchWord));
  };
  useEffect(() => {
    if (searchedItem) {
      // console.log(`[]searchedItem`);
      // console.log(items.length);
      const result = items.map((item) => {
        let allow = true;
        if (item?._id) {
          if (
            item._id
              .toLowerCase()
              .includes(searchedItem?.toString().toLowerCase())
          )
            return { ...item };
        }
        if (item?.user?.username) {
          if (
            item.user?.username
              .toLowerCase()
              .includes(searchedItem?.toString().toLowerCase())
          )
            return { ...item };
        }
        if (item?.name) {
          if (
            item.name
              .toLowerCase()
              .includes(searchedItem?.toString().toLowerCase())
          )
            return { ...item };
        } else return null;
        if (allow) return null;
      });
      const result2 = result.filter((item) => item !== null);
      setFilteredData(result2);
    }
  }, [searchedItem]);
  const vehicleChanged = (e) => {
    const result = items.map((item) => {
      const searchWord = e.target.value;
      let allow = true;
      if (item?.vehicle?.reg) {
        if (item.vehicle?.reg.toLowerCase().includes(searchWord.toLowerCase()))
          return { ...item };
      }
      if (item?.user?.username) {
        if (
          item.user?.username.toLowerCase().includes(searchWord.toLowerCase())
        )
          return { ...item };
      } else return null;
      if (allow) return null;
    });
    const result2 = result.filter((item) => item !== null);
    setFilteredData(result2);
    // const result = items.filter((item) => {
    //   const searchWord = e.target.value;

    //   if (item?.vehicle?.reg)
    //     return item.vehicle?.reg
    //       .toLowerCase()
    //       .includes(searchWord.toLowerCase());
    //   else return null;
    // });
  };

  useEffect(() => {
    if (itemsRaw && !itemsByUsername) {
      // console.log(`[]itemsRaw`);
      // console.log(itemsRaw.length);
      setFilteredData(itemsRaw);
      setItems(itemsRaw);
    }
  }, [itemsRaw]);
  useEffect(() => {
    // console.log(`[]filteredData`);
    setLoading(false);
  }, [filteredData]);
  useEffect(() => {
    // console.log(`[]items`);
    // console.log(items.length);
    idChanged(document.getElementById("searchField"));
  }, [items]);

  const setUpModal = (mode) => {
    dispatch(setItemModalMode(mode));
    dispatch(setIsItemsModalOpen(true));
  };
  // // console.log(`isItemsModalOpen ${isItemsModalOpen}`);
  let myClass = "fixedTable";
  let scroll = "scroll";
  if (view == "raw") {
    scroll = "";
    myClass = "rawItemsClass";
  }
  return (
    <>
      <TableContainer overflowY={scroll} className={myClass}>
        <Table variant="simple">
          {view != "raw" && (
            <TableCaption>
              {filteredData.length > 25 ? "25" : filteredData.length} z{" "}
              {itemsRaw?.length}
            </TableCaption>
          )}
          <Thead>
            <Tr>
              <Th>
                <Button
                  size="sm"
                  colorScheme="cyan"
                  onClick={() => setUpModal("add")}
                >
                  <AddIcon w={3} h={3} color="#fff" />
                </Button>
              </Th>
              <Th>
                nr/opis(nazwa)/klient
                {view !== "raw" && (
                  <>
                    <p>
                      <InputGroup>
                        <Input
                          id="searchField"
                          value={searchedItem}
                          placeholder="nr/opis(nazwa)/klient"
                          margin="0 0 5px 0"
                          // onBlur={(e) => {
                          //   e.target.value = "";
                          // }}
                          bg="#fafafa"
                          onChange={idChanged}
                        />
                        <InputRightElement>
                          <Button
                            color={"red"}
                            size={"sx"}
                            onClick={() => {
                              dispatch(setSearchedItem(null));
                            }}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </p>
                  </>
                )}
              </Th>
              <Th>transakcje</Th>
              <Th>parametry</Th>
              <Th>marka/model</Th>
              <Th>typ</Th>
              <Th>magazyn</Th>
              <Th>ilość</Th>
              <Th>uwagi</Th>
              <Th>zmodyfikowano</Th>
            </Tr>
          </Thead>
          <Tbody>
            <TBody view={view} items={filteredData} />
          </Tbody>
          {view != "raw" && (
            <Tfoot>
              <Tr>
                <Th></Th>
                <Th>nr/nazwa</Th>
                <Th>transakcje</Th>
                <Th>parametry</Th>
                <Th>marka/model</Th>
                <Th>typ</Th>
                <Th>magazyn</Th>
                <Th>ilość</Th>
                <Th>uwagi</Th>
                <Th>zmodyfikowano</Th>
              </Tr>
            </Tfoot>
          )}
        </Table>
      </TableContainer>
    </>
  );
};
export default ItemsTable;
