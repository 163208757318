import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import {
  FormControl,
  HStack,
  Input,
  useDisclosure,
  useToast,
  Box,
  Button,
  Container,
  Spinner,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { setServiceModalMode } from "../../../services/serviceModalModeSlice";
import { setIsServiceModalOpen } from "../../../services/isServiceModalOpenSlice";
import { setHour } from "../../../services/selectedHourSlice";
import { setMinute } from "../../../services/selectedMinuteSlice";
import { setUserObj } from "../../../users/selectedUserSlice";
import { useDispatch } from "react-redux";
import { setIsServiceAnonymous } from "../../../services/isServiceAnonymousSlice";
import {
  setEditedServiceId,
  setEditedServiceNotes,
} from "../../../services/editedServiceIdSlice";
const Parts = ({ row, part, index, length }) => {
  const dispatch = useDispatch();
  const setAppointment = ({ hour, minute }) => {
    dispatch(setHour(hour));
    dispatch(setMinute(minute));
    dispatch(setEditedServiceId(null));
    dispatch(setIsServiceAnonymous(false));
    dispatch(setEditedServiceNotes(""));
    dispatch(setUserObj(null));
    dispatch(setServiceModalMode("add"));
    dispatch(setIsServiceModalOpen(true));
  };
  // // // console.log(part);
  return (
    <Td className="nearestTd" style={{ cursor: "pointer" }} p={1}>
      {part.status ? (
        part.status === 1 ? (
          <Button
            isDisabled={(row.id === 11 || row.id === 14) && part.id === 0}
            w="100%"
            size="xs"
            bg={"orange.100"}
            onClick={() => setAppointment({ hour: row.id, minute: part.id })}
            _hover={{ bg: "#ffc98f" }}
          >
            {row.id} : {part.id}
            {part.id === 0 && "0"}
          </Button>
        ) : (
          <Button
            isDisabled={(row.id === 11 || row.id === 14) && part.id === 0}
            w="100%"
            size="xs"
            bg={"red.100"}
            onClick={() => setAppointment({ hour: row.id, minute: part.id })}
            _hover={{ bg: "#d6a3a3" }}
          >
            {row.id} : {part.id}
            {part.id === 0 && "0"}
          </Button>
        )
      ) : (
        <Button
          isDisabled={(row.id === 11 || row.id === 14) && part.id === 0}
          w="100%"
          size="xs"
          bg={"blue.100"}
          onClick={() => setAppointment({ hour: row.id, minute: part.id })}
          _hover={{ bg: "#b9d4eb" }}
        >
          {row.id} : {part.id}
          {part.id === 0 && "0"}
        </Button>
      )}
    </Td>
  );
};

export default Parts;
