import { Select, VStack } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setMinute } from "./selectedMinuteSlice";
import { useEffect, useState } from "react";
// import { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import { setId } from "./selectedUserSlice";
// import { setVehicleId } from "../vehicles/selectedVehicleSlice";
// import { useSelector } from "react-redux";
// import UsersSearchBox from "./UsersSearchBox";

const MinuteSelect = () => {
  const selectedMinute = useSelector((state) => state.selectedMinute.minute);
  const selectedHour = useSelector((state) => state.selectedHour.hour);
  const [list, setList] = useState(<></>);

  const dispatch = useDispatch();
  const onMinuteChanged = (e) => {
    dispatch(setMinute(e.target.value));
  };
  const [minutes, setMinutes] = useState([]);
  useEffect(() => {
    console.log(`"changed an hour": ${selectedHour}`);
    if (
      selectedHour !== 11 &&
      selectedHour !== "11" &&
      selectedHour !== "14" &&
      selectedHour !== 14
    ) {
      setMinutes(["00", "30"]);
    } else {
      // // // console.log("11");
      setMinutes(["30"]);
      dispatch(setMinute("30"));
    }
  }, [selectedHour]);
  useEffect(() => {
    // console.log("selectedMinute");
    // console.log(selectedMinute);
    // console.log("selectedMinute");
    // dispatch(setMinute(minutes[0]));
    setList(
      <Select
        id="select"
        onChange={onMinuteChanged}
        maxWidth="70px"
        value={selectedMinute.toString()}
        defaultValue={selectedMinute.toString()}
      >
        {minutes.map((m) => (
          <option key={m} value={m}>
            {m}
          </option>
        ))}
      </Select>
    );
  }, [minutes, selectedMinute]);
  // useEffect(() => {
  //   let element = document.getElementById("select");
  //   element.value = selectedMinute;
  // }, [list]);
  return list;
};

export default MinuteSelect;
