const daily = {
  station: [
    {
      parts: [
        {
          id: 0,
          status: 0,
        },
        {
          id: 30,
          status: 0,
        },
      ],
    },
  ],
};
export default daily;
// const daily = [
//   {
//     id: 7,
//     station: [
//       {
//         id: "1",
//         parts: [
//           {
//             id: 0,
//             status: 0,
//           },
//           {
//             id: 30,
//             status: 0,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     id: 8,
//     station: [
//       {
//         id: "1",
//         parts: [
//           {
//             id: 0,
//             status: 0,
//           },
//           {
//             id: 30,
//             status: 0,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     id: 9,
//     station: [
//       {
//         id: "1",
//         parts: [
//           {
//             id: 0,
//             status: 0,
//           },
//           {
//             id: 30,
//             status: 0,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     id: 10,
//     station: [
//       {
//         id: "1",
//         parts: [
//           {
//             id: 0,
//             status: 0,
//           },
//           {
//             id: 30,
//             status: 0,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     id: 11,
//     station: [
//       {
//         id: "1",
//         parts: [
//           {
//             id: 0,
//             status: 0,
//           },
//           {
//             id: 30,
//             status: 0,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     id: 12,
//     station: [
//       {
//         id: "1",
//         parts: [
//           {
//             id: 0,
//             status: 0,
//           },
//           {
//             id: 30,
//             status: 0,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     id: 13,
//     station: [
//       {
//         id: "1",
//         parts: [
//           {
//             id: 0,
//             status: 0,
//           },
//           {
//             id: 30,
//             status: 0,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     id: 14,
//     station: [
//       {
//         id: "1",
//         parts: [
//           {
//             id: 0,
//             status: 0,
//           },
//           {
//             id: 30,
//             status: 0,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     id: 15,
//     station: [
//       {
//         id: "1",
//         parts: [
//           {
//             id: 0,
//             status: 0,
//           },
//           {
//             id: 30,
//             status: 0,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     id: 16,
//     station: [
//       {
//         id: "1",
//         parts: [
//           {
//             id: 0,
//             status: 0,
//           },
//           {
//             id: 30,
//             status: 0,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     id: 17,
//     station: [
//       {
//         id: "1",
//         parts: [
//           {
//             id: 0,
//             status: 0,
//           },
//           {
//             id: 30,
//             status: 0,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     id: 18,
//     station: [
//       {
//         id: "1",
//         parts: [
//           {
//             id: 0,
//             status: 0,
//           },
//           {
//             id: 30,
//             status: 0,
//           },
//         ],
//       },
//     ],
//   },
// ];
// export default daily;
