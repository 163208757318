import { createSlice } from "@reduxjs/toolkit";

export const searchedItemSlice = createSlice({
  name: "searchedItem",
  initialState: {
    searchedItem: null,
  },
  reducers: {
    setSearchedItem: (state, action) => {
      // return { ...state, stIds: [...state.stIds, action.payload] };
      return { searchedItem: [action.payload] };
    },
  },
});

export const { setSearchedItem } = searchedItemSlice.actions;
export default searchedItemSlice.reducer;
