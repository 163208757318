import { createSlice } from "@reduxjs/toolkit";

export const editedServiceIdSlice = createSlice({
  name: "editedServiceId",
  initialState: {
    editedServiceId: null,
    editedServiceNotes: `null`,
  },
  reducers: {
    setEditedServiceId: (state, action) => {
      // // console.log(action.payload);
      state.editedServiceId = action.payload;
    },
    setEditedServiceNotes: (state, action) => {
      // // console.log(action.payload);
      state.editedServiceNotes = action.payload;
    },
  },
});

export const { setEditedServiceId, setEditedServiceNotes } =
  editedServiceIdSlice.actions;
export default editedServiceIdSlice.reducer;
