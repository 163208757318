import { Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import MyDoc from "./MyDoc";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useSelector } from "react-redux";

function PdfComponent({ appointment }) {
  const preferences = useSelector((state) => state.preferences);
  // console.log(appointment);
  return (
    <div>
      <PDFDownloadLink
        document={<MyDoc preferences={preferences} appointment={appointment} />}
        fileName={`${appointment?.user?.username}.pdf`}
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            <Button color={"blue.200"} bg="transparent" isDisabled={true}>
              <FontAwesomeIcon size="lg" icon={faFilePdf} />
            </Button>
          ) : (
            <Button color={"blue.200"} bg="transparent">
              <FontAwesomeIcon size="lg" icon={faFilePdf} />
            </Button>
          )
        }
      </PDFDownloadLink>
    </div>
  );
}
export default PdfComponent;
