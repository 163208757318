import { createSlice } from "@reduxjs/toolkit";
import { createReducer } from "@reduxjs/toolkit";

export const preferencesSlice = createSlice({
  name: "preferences",
  initialState: {
    garageId: "",
    itemTypes: [],
    pdf1: "",
    hourOpen: 0,
    hourClose: 23,
  },
  reducers: {
    setPreferences: (state, action) => {
      if (action.payload.target === "ItemTypes") {
        return {
          ...state,
          itemTypes: action.payload.value,
        };
      }
      if (action.payload.target === "hourOpen") {
        return {
          ...state,
          hourOpen: action.payload.value,
        };
      }
      if (action.payload.target === "hourClose") {
        return {
          ...state,
          hourClose: action.payload.value,
        };
      }
      if (action.payload.target === "pdf1") {
        return {
          ...state,
          pdf1: action.payload.value,
        };
      } else return { ...state };
    },
  },
});

export const { setPreferences } = preferencesSlice.actions;
export default preferencesSlice.reducer;
